import { useEffect, useRef } from 'react';

const useScroll = (handler: (event: Event) => void): void => {
    const handlerRef = useRef(handler);
    handlerRef.current = handler;

    useEffect(() => {
        const resizeHandler = (event: Event) => handlerRef.current(event);
        window.addEventListener('scroll', resizeHandler);
        return () => {
            window.removeEventListener('scroll', resizeHandler);
        };
    }, []);
};

export default useScroll;
