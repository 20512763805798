import { CSSProperties } from 'react';

export const setTransitionalCSSProperties = (
    element: HTMLElement | null,
    properties: [keyof CSSProperties, string][]
): void => {
    if (!element) {
        return;
    }

    requestAnimationFrame(() => {
        for (const [key, value] of properties) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            element.style[key] = value;
        }
    });
};

export const supportHeightTransitionFromZeroToAuto = (element: HTMLElement | null): void => {
    if (!element) {
        return;
    }

    requestAnimationFrame(() => {
        element.style.height = 'auto';
        const height = element.offsetHeight;
        element.style.height = '0';

        requestAnimationFrame(() => {
            const handleTransitionEnd = (e: TransitionEvent) => {
                if (element && e.target === element) {
                    element.style.height = 'auto';
                    element.removeEventListener('transitionend', handleTransitionEnd);
                }
            };
            element.addEventListener('transitionend', handleTransitionEnd);
            element.style.height = `${height}px`;
        });
    });
};
