import { forwardRef, ReactNode } from 'react';

import styles from './section.less';

interface SectionProps {
    id?: string;
    dataQa?: string;
    children: ReactNode;
}

// eslint-disable-next-line react/display-name
const Section = forwardRef<HTMLElement, SectionProps>(({ id, dataQa, children }, ref) => (
    <section className={styles.section} id={id} ref={ref} data-qa={dataQa}>
        {children}
    </section>
));

export default Section;
