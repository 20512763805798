import { FC, ReactNode } from 'react';

import SpinningStar from 'src/pages/Index/components/SpinningStar';

import styles from './styles.less';

interface PageProps {
    children: ReactNode;
}

const Page: FC<PageProps> = ({ children }) => (
    <div className={styles.pageContainer}>
        <div className={styles.page}>{children}</div>
        <SpinningStar />
    </div>
);

export default Page;
