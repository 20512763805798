import { FC } from 'react';

import { GridLayout } from '@hh.ru/magritte-ui';

import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import PageWrapper from 'src/components/PageWrapper';

import CareerPlanningSection from 'src/pages/Index/components/CareerPlanningSection';
import CoursesSection from 'src/pages/Index/components/CoursesSection';
import FeedbackSection from 'src/pages/Index/components/FeedbackSection';
import Main from 'src/pages/Index/components/Main';
import MainSection from 'src/pages/Index/components/MainSection';
import Page from 'src/pages/Index/components/Page';

const PageLayout: FC = () => (
    <PageWrapper>
        <Page>
            <Header absolute />
            <Main>
                <MainSection />
                <GridLayout>
                    <CoursesSection />
                </GridLayout>
                <CareerPlanningSection />
                <GridLayout>
                    <FeedbackSection />
                </GridLayout>
            </Main>
            <Footer />
        </Page>
    </PageWrapper>
);

export default PageLayout;
