import { FC, ReactNode, useMemo } from 'react';
import classNames from 'classnames';

import { GridColumn, GridRow, useBreakpoint } from '@hh.ru/magritte-ui';

import styles from './styles.less';

interface CareerPlanningBlockProps {
    flow: 'forward' | 'backward';
    description: ReactNode;
    action: ReactNode;
    content: ReactNode;
    dataQa: string;
}

const CareerPlanningBlock: FC<CareerPlanningBlockProps> = ({ content, description, flow, action, dataQa }) => {
    const { isMobile } = useBreakpoint();

    const descriptionBlock = useMemo(
        () => (
            <GridColumn xs={4} s={8} m={4} flexibleContent>
                <div
                    className={classNames(styles.description, {
                        [styles.descriptionPadding]: !isMobile && flow === 'backward',
                    })}
                >
                    {description}
                    <div>{!isMobile && action}</div>
                </div>
            </GridColumn>
        ),
        [isMobile, action, description, flow]
    );

    const contentBlock = (
        <GridColumn xs={4} s={8} m={8} flexibleContent>
            <div className={styles.content}>{content}</div>
        </GridColumn>
    );

    return (
        <GridRow rowGap={24} data-qa={dataQa}>
            {isMobile && (
                <>
                    {descriptionBlock}
                    {contentBlock}
                    {action}
                </>
            )}
            {!isMobile && flow === 'forward' && (
                <>
                    {descriptionBlock}
                    {contentBlock}
                </>
            )}
            {!isMobile && flow === 'backward' && (
                <>
                    {contentBlock}
                    {descriptionBlock}
                </>
            )}
        </GridRow>
    );
};

export default CareerPlanningBlock;
