import { DetailedHTMLProps, FC, ImgHTMLAttributes } from 'react';

interface AdaptivePictureProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    mobile: string;
    M: string;
    L: string;
    XL: string;
    XXL: string;
}

const AdaptivePicture: FC<AdaptivePictureProps> = ({ mobile, M, L, XL, XXL, ...imgProps }) => {
    return (
        <picture>
            <source srcSet={mobile} media="(max-width: 1024px)" />
            <source srcSet={M} media="(max-width: 1280px)" />
            <source srcSet={L} media="(max-width: 1440px)" />
            <source srcSet={XL} media="(max-width: 1920px)" />
            <source srcSet={XXL} media="(min-width: 1920px)" />

            <img src={mobile} loading="lazy" {...imgProps} />
        </picture>
    );
};

export default AdaptivePicture;
