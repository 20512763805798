import { fetcher } from '@hh.ru/front-static-app';

const FEEDBACK = '/shards/feedback/post_feedback';

interface FeedbackValues {
    text: string;
    email: string;
}

declare global {
    interface FetcherPostApi {
        [FEEDBACK]: { body: FeedbackValues; queryParams: void; response: void };
    }
}

const postFeedback = async (values: FeedbackValues): Promise<void> => {
    try {
        await fetcher.post(FEEDBACK, values);
    } catch (e) {
        throw e;
    }
};

export default postFeedback;
