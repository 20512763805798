import { translation, TranslatedComponent } from '@hh.ru/front-static-app';

import { LOGIN_ROUTE } from 'src/app/routesUtils';
import BannerLoginTemplate from 'src/components/BannerLogin/BannerLoginTemplate';
import DottedList from 'src/components/DottedList';
import getDataQa from 'src/utils/getDataQa';

const TrlKeys = {
    enterText: 'career-platform.components.loginBanner.enterText',
    listDescription: {
        0: 'career-platform.components.loginBanner.listDescription.0',
        1: 'career-platform.components.loginBanner.listDescription.1',
        2: 'career-platform.components.loginBanner.listDescription.2',
    },
    title: 'career-platform.components.loginBanner.title',
};

const DATA_QA_BLOCK = 'banner-login-landing';

const BannerLoginLanding: TranslatedComponent = ({ trls }) => (
    <BannerLoginTemplate
        title={trls[TrlKeys.title]}
        description={<DottedList strings={Object.values(TrlKeys.listDescription).map((key) => trls[key])} />}
        link={{
            to: LOGIN_ROUTE,
            content: trls[TrlKeys.enterText],
            dataQa: getDataQa(DATA_QA_BLOCK, 'login'),
        }}
        reverse
        dataQaContainer={getDataQa(DATA_QA_BLOCK)}
        dataQaTitle={getDataQa(DATA_QA_BLOCK, 'title')}
        dataQaDescription={getDataQa(DATA_QA_BLOCK, 'description')}
    />
);
export default translation(BannerLoginLanding);
