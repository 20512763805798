import React from 'react';

import { translation, TranslatedComponent } from '@hh.ru/front-static-app';
import { GridColumn, GridRow, useBreakpoint } from '@hh.ru/magritte-ui';

import FormFeedback from 'src/components/FormFeedback';
import TitleSecondary, { TitleSecondaryAlignment, TitleSecondarySize } from 'src/components/TitleSecondary';
import Section from 'src/pages/Index/components/Section';

import styles from './styles.less';

const TrlKeys = {
    title: 'career-platform.landing.feedback.title',
};

const FeedbackSection: TranslatedComponent = ({ trls }) => {
    const { isXS, isS } = useBreakpoint();
    const isMobile = isXS || isS;

    return (
        <GridRow>
            <GridColumn xs={0} s={0} m={2} />
            <GridColumn xs={4} s={8} m={8}>
                <Section>
                    <div className={styles.content}>
                        <TitleSecondary
                            size={isMobile ? TitleSecondarySize.Size4 : TitleSecondarySize.Size2}
                            alignment={TitleSecondaryAlignment.Center}
                            Element="h3"
                        >
                            {trls[TrlKeys.title]}
                        </TitleSecondary>
                        <FormFeedback />
                    </div>
                </Section>
            </GridColumn>
            <GridColumn xs={0} s={0} m={2} />
        </GridRow>
    );
};

export default translation(FeedbackSection);
