import { useCallback, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { FormApi } from 'final-form';
import createDecorator from 'final-form-focus';
import { object, string } from 'yup';

import { translation, TranslatedComponent } from '@hh.ru/front-static-app';
import { Button, Input, TextArea } from '@hh.ru/magritte-ui';
import { CheckCircleFilledSize24, MinusCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';

import FormDisclaimer from 'src/components/FormDisclaimer';
import useFormValidation from 'src/hooks/useFormValidation';
import useSnackbar from 'src/hooks/useSnackbar';
import { analytics, Goal } from 'src/utils/analytics';
import { EMAIL_REGEXP } from 'src/utils/regexp';

import postFeedback from 'src/components/FormFeedback/api/postFeeback';

import styles from './styles.less';

const TrlKeys = {
    textarea: {
        placeholder: 'career-platform.components.form-feedback.textarea.placeholder',
        error: 'career-platform.components.form-feedback.textarea.error',
    },
    email: {
        placeholder: 'career-platform.components.form-feedback.email.placeholder',
        errors: {
            required: 'career-platform.components.form-feedback.email.errors.required',
            pattern: 'career-platform.components.form-feedback.email.errors.pattern',
        },
    },
    snack: {
        success: 'career-platform.components.form-feedback.snack.success',
        error: 'career-platform.components.form-feedback.snack.error',
    },
    button: 'career-platform.components.form-feedback.button',
};

interface FormValues {
    text: string;
    email: string;
}

const FIELD_TEXT = 'text';
const FIELD_EMAIL = 'email';
const INITIAL_VALUES: FormValues = { [FIELD_TEXT]: '', [FIELD_EMAIL]: '' };

const focusOnError = createDecorator<FormValues>();
const decorators = [focusOnError];

const FormFeedback: TranslatedComponent = ({ trls }) => {
    const validationSchema = useMemo(
        () =>
            object({
                [FIELD_TEXT]: string().required(trls[TrlKeys.textarea.error]),
                [FIELD_EMAIL]: string()
                    .matches(EMAIL_REGEXP, trls[TrlKeys.email.errors.pattern])
                    .required(trls[TrlKeys.email.errors.required]),
            }),
        [trls]
    );

    const { onValidate, showErrorOnBlur } = useFormValidation(validationSchema);
    const { showSnack, hideSnack } = useSnackbar();

    const onSubmit = useCallback(
        async (values: FormValues, form: FormApi<FormValues>) => {
            try {
                await postFeedback(values);
                analytics.reach(Goal.send_feedback_submit);
                showSnack({
                    children: trls[TrlKeys.snack.success],
                    addon: <CheckCircleFilledSize24 initial="positive" />,
                    onClose: hideSnack,
                    showClose: true,
                });
                setTimeout(() => form.restart());
            } catch (ignore) {
                showSnack({
                    children: trls[TrlKeys.snack.error],
                    addon: <MinusCircleFilledSize24 initial="negative" />,
                    onClose: hideSnack,
                    showClose: true,
                });
            }
        },
        [hideSnack, showSnack, trls]
    );

    return (
        <div>
            <Form
                onSubmit={onSubmit}
                decorators={decorators}
                validate={onValidate}
                initialValues={INITIAL_VALUES}
                render={({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <Field<FormValues[typeof FIELD_TEXT]>
                            name={FIELD_TEXT}
                            render={({ input, meta }) => (
                                <TextArea
                                    name={input.name}
                                    placeholder={trls[TrlKeys.textarea.placeholder]}
                                    value={input.value}
                                    onChange={input.onChange}
                                    onFocus={input.onFocus}
                                    onBlur={input.onBlur}
                                    layout="fill-horizontal"
                                    disabled={meta.submitting}
                                    invalid={showErrorOnBlur(meta)}
                                    errorMessage={meta.error as string}
                                />
                            )}
                        />
                        <div className={styles.secondPart}>
                            <div className={styles.inputWrapper}>
                                <Field<FormValues[typeof FIELD_EMAIL]>
                                    name={FIELD_EMAIL}
                                    render={({ input, meta }) => (
                                        <Input
                                            name={input.name}
                                            placeholder={trls[TrlKeys.email.placeholder]}
                                            size="large"
                                            value={input.value}
                                            onChange={input.onChange}
                                            onFocus={input.onFocus}
                                            onBlur={input.onBlur}
                                            disabled={meta.submitting}
                                            invalid={showErrorOnBlur(meta)}
                                            errorMessage={meta.error as string}
                                        />
                                    )}
                                />
                            </div>
                            <div className={styles.buttonWrapper}>
                                <Button mode="primary" size="large" loading={submitting} stretched type="submit">
                                    {trls[TrlKeys.button]}
                                </Button>
                            </div>
                        </div>
                    </form>
                )}
            />
            <div className={styles.disclaimer}>
                <FormDisclaimer buttonName={trls[TrlKeys.button]} />
            </div>
        </div>
    );
};

export default translation(FormFeedback);
