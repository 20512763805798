import { FC, useCallback, useEffect, useRef } from 'react';

import useScroll from 'src/hooks/useScroll';
import { setTransitionalCSSProperties } from 'src/utils/animationUtils';

import styles from './styles.less';

const getDeg = () => {
    const scrollTop = window.scrollY;
    const docHeight = document.body.offsetHeight;
    const winHeight = window.innerHeight;
    const scrollPercent = scrollTop / (docHeight - winHeight);
    return scrollPercent * 30;
};

const getCssRotateDeg = () => `rotate(${getDeg()}deg)`;

const SpinningStar: FC = () => {
    const ref = useRef<HTMLDivElement>(null);
    const onScroll = useCallback(() => {
        const elem = ref.current;
        if (elem) {
            setTransitionalCSSProperties(elem, [['transform', getCssRotateDeg()]]);
        }
    }, []);

    useScroll(onScroll);

    useEffect(() => onScroll(), [onScroll]);

    return (
        <div className={styles.spinningStarContainer}>
            <div ref={ref} className={styles.spinningStar} />
        </div>
    );
};

export default SpinningStar;
