import { useCallback, useMemo } from 'react';

import careerMainBasementButtonClick from '@hh.ru/analytics-js-events/build/career_platform/main/career_main_basement_button_click';
import { translation, TranslatedComponent } from '@hh.ru/front-static-app';
import {
    Button,
    Card,
    GridColumn,
    GridLayout,
    GridRow,
    Text,
    Title,
    useBreakpoint,
    VSpacing,
    VSpacingContainer,
} from '@hh.ru/magritte-ui';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { usePush } from '@hh.ru/redux-spa-middleware';
import Link from '@hh.ru/redux-spa-middleware/lib/Link';

import { getLKRouteHref, getLoginRoute } from 'src/app/routesUtils';
import AdaptivePicture from 'src/components/AdaptivePicture';
import BannerLoginLanding from 'src/components/BannerLogin/BannerLoginLanding';
import { LKSectionState } from 'src/models/pages/lk';
import Section from 'src/pages/Index/components/Section';
import getDataQa from 'src/utils/getDataQa';
import getKebabCase from 'src/utils/getKebabCase';
import pluralize from 'src/utils/pluralize';

import CareerPlanningBlock from 'src/pages/Index/components/CareerPlanningSection/CareerPlanningBlock';

import IconCalculator from './assets/calculator/calculator_icon.svg?react';
import calculatorImageL1 from './assets/calculator/calculator_l_1.png';
import calculatorImageL2 from './assets/calculator/calculator_l_2.png';
import calculatorImageM1 from './assets/calculator/calculator_m_1.png';
import calculatorImageM2 from './assets/calculator/calculator_m_2.png';
import calculatorImageMobile1 from './assets/calculator/calculator_mobile_1.png';
import calculatorImageMobile2 from './assets/calculator/calculator_mobile_2.png';
import calculatorImageXL1 from './assets/calculator/calculator_xl_1.png';
import calculatorImageXL2 from './assets/calculator/calculator_xl_2.png';
import calculatorImageXXL1 from './assets/calculator/calculator_xxl_1.png';
import calculatorImageXXL2 from './assets/calculator/calculator_xxl_2.png';
import IconCareerTrack from './assets/careerTrack/career_track_icon.svg?react';
import careerTrackMobile from './assets/careerTrack/cr_trk.png';
import careerTrackL from './assets/careerTrack/cr_trk_l.png';
import careerTrackM from './assets/careerTrack/cr_trk_m.png';
import careerTrackXL from './assets/careerTrack/cr_trk_xl.png';
import careerTrackXXL from './assets/careerTrack/cr_trk_xxl.png';
import IconMentors from './assets/mentors/mentors_icon.svg?react';
import mentorsL from './assets/mentors/mentors_l.png';
import mentorsM from './assets/mentors/mentors_m.png';
import mentorsMobile from './assets/mentors/mentors_mobile.png';
import mentorsXL from './assets/mentors/mentors_xl.png';
import mentorsXXL from './assets/mentors/mentors_xxl.png';
import providerCityBusinessSchool from './assets/providers/city_business_school.png';
import providerEduson from './assets/providers/eduson.png';
import IconProviders from './assets/providers/providers_icon.svg?react';
import providerSfEducation from './assets/providers/sf_education.png';
import providerSkillFactory from './assets/providers/skill_factory.png';
import providerXYZ from './assets/providers/xyz.png';
import providerYandexPracticum from './assets/providers/yandex_practicum.png';
import IconSuitProfessions from './assets/suitProfessions/suit_professions_icon.svg?react';
import suitProfessionsL1 from './assets/suitProfessions/suit_professions_l_1.png';
import suitProfessionsL2 from './assets/suitProfessions/suit_professions_l_2.png';
import suitProfessionsM1 from './assets/suitProfessions/suit_professions_m_1.png';
import suitProfessionsM2 from './assets/suitProfessions/suit_professions_m_2.png';
import suitProfessionsMobile1 from './assets/suitProfessions/suit_professions_mobile_1.png';
import suitProfessionsMobile2 from './assets/suitProfessions/suit_professions_mobile_2.png';
import suitProfessionsXL1 from './assets/suitProfessions/suit_professions_xl_1.png';
import suitProfessionsXL2 from './assets/suitProfessions/suit_professions_xl_2.png';
import suitProfessionsXXL1 from './assets/suitProfessions/suit_professions_xxl_1.png';
import suitProfessionsXXL2 from './assets/suitProfessions/suit_professions_xxl_2.png';

import styles from './styles.less';

const TrlKeys = {
    title: 'career-platform.landing.career-planning-section.title',
    description: 'career-platform.landing.career-planning-section.description',
    blocks: {
        calculator: {
            title: 'career-platform.landing.career-planning-section.calculator.title',
            description: 'career-platform.landing.career-planning-section.calculator.description',
        },
        providers: {
            title: 'career-platform.landing.career-planning-section.providers.title',
            description: 'career-platform.landing.career-planning-section.providers.description',
            andMore: 'career-platform.landing.career-planning-section.andMore',
        },
        careerTrack: {
            title: 'career-platform.landing.career-planning-section.careerTrack.title',
            description: 'career-platform.landing.career-planning-section.careerTrack.description',
        },
        suitableProfessions: {
            title: 'career-platform.landing.career-planning-section.suitableProfessions.title',
            description: 'career-platform.landing.career-planning-section.suitableProfessions.description',
        },
        mentors: {
            title: 'career-platform.landing.career-planning-section.mentors.title',
            description: 'career-platform.landing.career-planning-section.mentors.description',
        },
    },
    tryButton: 'career-platform.landing.career-planning-section.tryButton',
    provider: {
        one: 'career-platform.pluralize.provider.one',
        some: 'career-platform.pluralize.provider.some',
        many: 'career-platform.pluralize.provider.many',
    },
};

const PROVIDERS = [
    { name: 'skillfactory', src: providerSkillFactory },
    { name: 'Eduson academy', src: providerEduson },
    { name: 'ЯПрактикум', src: providerYandexPracticum },
    { name: 'SF Education', src: providerSfEducation },
    { name: 'City Business School', src: providerCityBusinessSchool },
    { name: 'XYZ school', src: providerXYZ },
];

const PROVIDERS_MOBILE = [
    { name: 'Eduson academy', src: providerEduson },
    { name: 'ЯПрактикум', src: providerYandexPracticum },
    { name: 'skillfactory', src: providerSkillFactory },
    { name: 'SF Education', src: providerSfEducation },
    { name: 'City Business School', src: providerCityBusinessSchool },
    { name: 'XYZ school', src: providerXYZ },
];

enum BlockTypes {
    Calculator = 'Calculator',
    Providers = 'Providers',
    CareerTrack = 'CareerTrack',
    SuitableProfessions = 'SuitableProfessions',
    Mentors = 'Mentors',
}

const DATA_QA_BLOCK = 'planning-section';

const CareerPlanningSection: TranslatedComponent = ({ trls }) => {
    const { isMobile } = useBreakpoint();
    const { providers } = useSelectorNonNullable((state) => state.landing.counters);

    const push = usePush();

    const ToLKButton = useCallback(
        (blockType: BlockTypes) => (
            <Button
                mode="primary"
                size="medium"
                style="neutral"
                stretched={isMobile}
                Element={Link}
                to={getLoginRoute(getLKRouteHref())}
                onClick={() => careerMainBasementButtonClick({ buttonName: 'try_and_authorize', type: blockType })}
                data-qa={getDataQa('button')}
            >
                {trls[TrlKeys.tryButton]}
            </Button>
        ),
        [isMobile, trls]
    );

    const ToLKReskillButton = useMemo(
        () => (
            <Button
                mode="primary"
                size="medium"
                style="neutral"
                stretched={isMobile}
                Element={Link}
                to={getLoginRoute(getLKRouteHref(LKSectionState.ReSkill))}
                onClick={() =>
                    careerMainBasementButtonClick({
                        buttonName: 'try_and_authorize',
                        type: BlockTypes.CareerTrack,
                    })
                }
                data-qa={getDataQa('button')}
            >
                {trls[TrlKeys.tryButton]}
            </Button>
        ),
        [trls, isMobile]
    );

    const handleImageClick = (blockType: BlockTypes) => {
        careerMainBasementButtonClick({ buttonName: 'main_picture_click', type: blockType });
        push(getLoginRoute(getLKRouteHref()));
    };

    const handleImageCareerTrackClick = () => {
        push(getLoginRoute(getLKRouteHref(LKSectionState.ReSkill)));
        careerMainBasementButtonClick({ buttonName: 'main_picture_click', type: BlockTypes.CareerTrack });
    };

    return (
        <Section dataQa={getDataQa(DATA_QA_BLOCK)}>
            <div className={styles.sectionContent}>
                <GridLayout>
                    <Title
                        size="extra-large"
                        description={trls[TrlKeys.description]}
                        alignment="center"
                        Element="h2"
                        dataQaTitle={getDataQa(DATA_QA_BLOCK, 'title')}
                    >
                        {trls[TrlKeys.title]}
                    </Title>
                    <VSpacing default={40} gteS={64} />
                    <VSpacingContainer default={isMobile ? 60 : (160 as unknown as 80)}>
                        <CareerPlanningBlock
                            flow="backward"
                            dataQa={getDataQa(DATA_QA_BLOCK, 'section', getKebabCase(BlockTypes.Calculator))}
                            action={ToLKButton(BlockTypes.Calculator)}
                            description={
                                <>
                                    <IconCalculator width={isMobile ? 32 : 48} height={isMobile ? 32 : 48} />
                                    <VSpacing default={16} />
                                    <Title
                                        size="large"
                                        description={trls[TrlKeys.blocks.calculator.description]}
                                        Element="h3"
                                        dataQaTitle={getDataQa('title')}
                                        dataQaDescription={getDataQa('description')}
                                    >
                                        {trls[TrlKeys.blocks.calculator.title]}
                                    </Title>
                                    <VSpacing default={0} gteS={32} />
                                </>
                            }
                            content={
                                <GridRow rowGap={16}>
                                    <GridColumn xs={4} s={8} m={12} l={4} xl={4} xxl={4}>
                                        <Card padding={12} borderRadius={24} showShadow stretched={isMobile}>
                                            <AdaptivePicture
                                                onClick={() => handleImageClick(BlockTypes.Calculator)}
                                                className={styles.img}
                                                mobile={calculatorImageMobile1}
                                                M={calculatorImageM1}
                                                L={calculatorImageL1}
                                                XL={calculatorImageXL1}
                                                XXL={calculatorImageXXL1}
                                                alt={trls[TrlKeys.blocks.calculator.title]}
                                            />
                                        </Card>
                                    </GridColumn>
                                    <GridColumn xs={4} s={8} m={12} l={4} xl={4} xxl={4}>
                                        <Card padding={12} borderRadius={24} showShadow stretched={isMobile}>
                                            <AdaptivePicture
                                                onClick={() => handleImageClick(BlockTypes.Calculator)}
                                                className={styles.img}
                                                mobile={calculatorImageMobile2}
                                                M={calculatorImageM2}
                                                L={calculatorImageL2}
                                                XL={calculatorImageXL2}
                                                XXL={calculatorImageXXL2}
                                                alt={trls[TrlKeys.blocks.calculator.title]}
                                            />
                                        </Card>
                                    </GridColumn>
                                </GridRow>
                            }
                        />
                        <CareerPlanningBlock
                            flow="forward"
                            dataQa={getDataQa(DATA_QA_BLOCK, 'section', getKebabCase(BlockTypes.Providers))}
                            action={ToLKButton(BlockTypes.Providers)}
                            description={
                                <>
                                    <IconProviders width={isMobile ? 32 : 48} height={isMobile ? 32 : 48} />
                                    <VSpacing default={16} />
                                    <Title
                                        size="large"
                                        description={trls[TrlKeys.blocks.providers.description]}
                                        Element="h3"
                                        dataQaTitle={getDataQa('title')}
                                        dataQaDescription={getDataQa('description')}
                                    >
                                        {trls[TrlKeys.blocks.providers.title]}
                                    </Title>
                                    <VSpacing default={0} gteS={32} />
                                </>
                            }
                            content={
                                <Card borderRadius={isMobile ? 24 : 40} showShadow padding={40} stretched={isMobile}>
                                    <div className={styles.providersCard}>
                                        {(isMobile ? PROVIDERS_MOBILE : PROVIDERS).map(({ name, src }) => (
                                            <img
                                                key={name}
                                                onClick={() => handleImageClick(BlockTypes.Providers)}
                                                className={styles.providerImg}
                                                src={src}
                                                alt={name}
                                                loading="lazy"
                                            />
                                        ))}
                                        <div className={styles.providersCardText}>
                                            <Text style="secondary" typography="label-2-regular">
                                                {trls[TrlKeys.blocks.providers.andMore]}{' '}
                                                {providers - (isMobile ? PROVIDERS_MOBILE.length : PROVIDERS.length)}{' '}
                                                {pluralize({
                                                    count:
                                                        providers -
                                                        (isMobile ? PROVIDERS_MOBILE.length : PROVIDERS.length),
                                                    one: trls[TrlKeys.provider.one],
                                                    some: trls[TrlKeys.provider.some],
                                                    many: trls[TrlKeys.provider.many],
                                                })}
                                            </Text>
                                        </div>
                                    </div>
                                </Card>
                            }
                        />
                        <CareerPlanningBlock
                            flow="backward"
                            dataQa={getDataQa(DATA_QA_BLOCK, 'section', getKebabCase(BlockTypes.CareerTrack))}
                            action={ToLKReskillButton}
                            description={
                                <>
                                    <IconCareerTrack width={isMobile ? 32 : 48} height={isMobile ? 32 : 48} />
                                    <VSpacing default={16} />
                                    <Title
                                        size="large"
                                        description={trls[TrlKeys.blocks.careerTrack.description]}
                                        Element="h3"
                                        dataQaTitle={getDataQa('title')}
                                        dataQaDescription={getDataQa('description')}
                                    >
                                        {trls[TrlKeys.blocks.careerTrack.title]}
                                    </Title>
                                    <VSpacing default={0} gteS={32} />
                                </>
                            }
                            content={
                                <Card
                                    borderRadius={isMobile ? 32 : 40}
                                    showShadow
                                    padding={isMobile ? 12 : 24}
                                    stretched={isMobile}
                                >
                                    <AdaptivePicture
                                        onClick={handleImageCareerTrackClick}
                                        className={styles.img}
                                        mobile={careerTrackMobile}
                                        M={careerTrackM}
                                        L={careerTrackL}
                                        XL={careerTrackXL}
                                        XXL={careerTrackXXL}
                                        alt={trls[TrlKeys.blocks.careerTrack.title]}
                                    />
                                </Card>
                            }
                        />
                        <CareerPlanningBlock
                            flow="forward"
                            dataQa={getDataQa(DATA_QA_BLOCK, 'section', getKebabCase(BlockTypes.SuitableProfessions))}
                            action={ToLKButton(BlockTypes.SuitableProfessions)}
                            description={
                                <>
                                    <IconSuitProfessions width={isMobile ? 32 : 48} height={isMobile ? 32 : 48} />
                                    <VSpacing default={16} />
                                    <Title
                                        size="large"
                                        description={trls[TrlKeys.blocks.suitableProfessions.description]}
                                        Element="h3"
                                        dataQaTitle={getDataQa('title')}
                                        dataQaDescription={getDataQa('description')}
                                    >
                                        {trls[TrlKeys.blocks.suitableProfessions.title]}
                                    </Title>
                                    <VSpacing default={0} gteS={32} />
                                </>
                            }
                            content={
                                <GridRow rowGap={16}>
                                    <GridColumn xs={4} s={8} m={4} l={4} xl={4} xxl={4}>
                                        <Card padding={16} borderRadius={24} showShadow stretched={isMobile}>
                                            <AdaptivePicture
                                                onClick={() => handleImageClick(BlockTypes.SuitableProfessions)}
                                                className={styles.img}
                                                mobile={suitProfessionsMobile1}
                                                M={suitProfessionsM1}
                                                L={suitProfessionsL1}
                                                XL={suitProfessionsXL1}
                                                XXL={suitProfessionsXXL1}
                                                alt={trls[TrlKeys.blocks.suitableProfessions.title]}
                                            />
                                        </Card>
                                    </GridColumn>
                                    <GridColumn xs={4} s={8} m={4} l={4} xl={4} xxl={4}>
                                        <Card padding={16} borderRadius={24} showShadow stretched={isMobile}>
                                            <AdaptivePicture
                                                onClick={() => handleImageClick(BlockTypes.SuitableProfessions)}
                                                className={styles.img}
                                                mobile={suitProfessionsMobile2}
                                                M={suitProfessionsM2}
                                                L={suitProfessionsL2}
                                                XL={suitProfessionsXL2}
                                                XXL={suitProfessionsXXL2}
                                                alt={trls[TrlKeys.blocks.suitableProfessions.title]}
                                            />
                                        </Card>
                                    </GridColumn>
                                </GridRow>
                            }
                        />
                        <CareerPlanningBlock
                            flow="backward"
                            dataQa={getDataQa(DATA_QA_BLOCK, 'section', getKebabCase(BlockTypes.Mentors))}
                            action={ToLKButton(BlockTypes.Mentors)}
                            description={
                                <>
                                    <IconMentors width={isMobile ? 32 : 48} height={isMobile ? 32 : 48} />
                                    <VSpacing default={16} />
                                    <Title
                                        size="large"
                                        description={trls[TrlKeys.blocks.mentors.description]}
                                        Element="h3"
                                        dataQaTitle={getDataQa('title')}
                                        dataQaDescription={getDataQa('description')}
                                    >
                                        {trls[TrlKeys.blocks.mentors.title]}
                                    </Title>
                                    <VSpacing default={0} gteS={32} />
                                </>
                            }
                            content={
                                <Card borderRadius={40} showShadow padding={isMobile ? 0 : 24}>
                                    <AdaptivePicture
                                        onClick={() => handleImageClick(BlockTypes.Mentors)}
                                        className={styles.img}
                                        mobile={mentorsMobile}
                                        M={mentorsM}
                                        L={mentorsL}
                                        XL={mentorsXL}
                                        XXL={mentorsXXL}
                                        alt={trls[TrlKeys.blocks.mentors.title]}
                                    />
                                </Card>
                            }
                        />
                    </VSpacingContainer>
                </GridLayout>
            </div>
            <div className={styles.loginBannerContainer}>
                <BannerLoginLanding />
            </div>
        </Section>
    );
};

export default translation(CareerPlanningSection);
