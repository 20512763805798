import { FC } from 'react';

import styles from './styles.less';

interface DottedListProps {
    strings: string[];
}

const DottedList: FC<DottedListProps> = ({ strings = [] }) => {
    return (
        <ul className={styles.list}>
            {strings.map((s, i) => (
                <li key={i}>{s}</li>
            ))}
        </ul>
    );
};

export default DottedList;
