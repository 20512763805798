import { SalaryDto } from 'src/types/common';
import { CourseDto } from 'src/types/courses';
import { ProfessionDescriptionDto } from 'src/types/profession';
import { ProfileSkillDto } from 'src/types/profile';
import { TaskDto } from 'src/types/task';

export enum LKSectionState {
    Empty = 'empty',
    UpSkill = 'upskill',
    ReSkill = 'reskill',
}
export enum LKScroll {
    Mentors = 'mentors',
}

export interface PageLKState {
    currentSection: LKSectionState;
    coursesByPopularSkills: CourseDto[];
    coursesWithHighSalaryOneYear: CourseDto[];
    upskillCoursesBySkill: CourseDto[];
    upskillCoursesByProfession: CourseDto[];
    widgetTasks: TaskDto[];
    widgetSkills: {
        skills: ProfileSkillDto[];
        salary: SalaryDto;
    } | null;
    widgetSalaryByGrades: ProfessionDescriptionDto | null;
}
